<template>
  <div v-if="profile" class="layout-profile">
    <div>
      <span class="numberCircle clrOrange"
        ><span>{{ capitalLetter }}</span></span
      >
    </div>
    <button class="p-link layout-profile-link" @click="expanded = !expanded">
      <span class="username">{{ profile.name }} {{ profile.surname }}</span>
      <i class="pi pi-fw pi-cog" />
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <button class="p-link" data-testid="logout" @click="toLogout">
            <i class="pi pi-fw pi-power-off" /><span>{{
              $t("section.logout")
            }}</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../config";
import { USER_LOGOUT } from "../store/modules/auth/auth.actions.type";
import { GET_PROFILE } from "../store/modules/auth/auth.getters.type";

export default {
  name: "ThePanelProfile",
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { t } = useI18n();
    const expanded = ref(false);
    const profile = computed(() => store.getters[GET_PROFILE]);

    const capitalLetter = computed(() => profile.value.name.charAt(0));

    async function toLogout() {
      await store.dispatch(USER_LOGOUT);
      await router.push({ name: "login" });
      toast.add({
        severity: "success",
        summary: t("common.success"),
        detail: t("logout.toast_success"),
        life: TOAST_LIFE,
      });
    }

    return {
      capitalLetter,
      profile,
      expanded,
      toLogout,
    };
  },
};
</script>

<style scoped>
.numberCircle {
  font-family: "OpenSans-Semibold", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  font-weight: bold;
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 0;
  border-radius: 50%;
  font-size: 28px;
  min-width: 50px;
  min-height: 50px;
  margin: 10px;
}

.numberCircle span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 1px;
  margin-right: 1px;
}

/* Some Back Ground Colors */
.clrGreen {
  background: #51a529;
}
.clrRose {
  background: #e6568b;
}
.clrOrange {
  background: #ec8234;
}
.clrBlueciel {
  background: #21adfc;
}
.clrMauve {
  background: #7b5d99;
}
</style>
