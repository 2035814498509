<template>
  <div class="centered">
    <progress-spinner v-if="loading" />
  </div>
  <BlockUI :blocked="loading" :full-screen="true">
    <router-view />
  </BlockUI>
</template>

<script>
import { computed } from "@vue/reactivity";
import BlockUI from "primevue/blockui";
import ProgressSpinner from "primevue/progressspinner";
import { useStore } from "vuex";

import { GET_LOADING_STATUS } from "../../store/modules/global/global.getters.type";

export default {
  name: "Panel",
  components: {
    ProgressSpinner,
    BlockUI,
  },
  setup() {
    const store = useStore();
    const loading = computed(() => store.getters[GET_LOADING_STATUS]);

    return {
      loading,
    };
  },
};
</script>

<style scoped>
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
