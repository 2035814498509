<template>
  <div class="layout-topbar">
    <button class="p-link layout-menu-button" @click="onMenuToggle">
      <span class="pi pi-bars" />
    </button>
    <div class="layout-topbar-icons">
      {{ $t("top_bar.welcome") }} <strong>{{ profile.name }}</strong>
      <button class="p-link">
        <span class="layout-topbar-item-text">User</span>
        <span class="layout-topbar-icon pi pi-user" />
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";

import { GET_PROFILE } from "../store/modules/auth/auth.getters.type";

export default {
  name: "ThePanelTopBar",
  emits: ["menu-toggle"],
  setup(props, { emit }) {
    const store = useStore();
    const profile = computed(() => store.getters[GET_PROFILE] ?? "");

    function onMenuToggle(event) {
      emit("menu-toggle", event);
    }

    return {
      profile,
      onMenuToggle,
    };
  },
};
</script>
